<template>
  <b-form-group :label="$t('options.search.title')">
    <b-input-group>
      <template #append>
        <b-input-group-text>
          <svg viewBox="0 0 19 19">
            <circle cx="7.5" cy="7.5" r="4" />
            <path d="M 10.5,10.5 L 15.5,15.5" />
          </svg>
        </b-input-group-text>
      </template>
      <b-form-input
        :value="value" @input="$emit('input', $event)" id="search-input"
        trim
      />
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  name: 'SearchInput',

  props: {
    value: { type: String, required: true }
  }
}
</script>

<style lang="scss" scoped>
.form-control[type='text'] {
  border-color: $black;
  border-right: 0;
  height: calc(1.75em + #{2 * $border-width});
}

.input-group-text {
  border-color: $black;
  background-color: $white;
  padding: 0;
}

svg {
  display: inline-block;
  width: 19px;
  height: 19px;
  align-self: center;
  margin-right: .25rem;

  path,
  circle {
    fill: none;
    stroke: $black;
    stroke-width: 2px;
    stroke-linecap: round;
  }
}
</style>
