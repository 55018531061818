<template>
  <b-form-tags
    v-bind="$attrs" v-on="$listeners"
    :value="value"
    no-outer-focus
  >
    <template v-slot="{ tags, disabled, addTag, removeTag }">
      <b-dropdown
        :text="buttonText"
        variant="outline-dark" class="tags-dropdown"
        dropright
      >
        <b-dropdown-item-button
          v-for="tag in availableOptions" :key="tag"
          @click="addTag(tag)"
        >
          {{ tag }}
        </b-dropdown-item-button>
      </b-dropdown>

      <ul v-if="tags.length > 0" class="list-inline d-inline-block">
        <li v-for="tag in tags" :key="tag + '-item'" class="list-inline-item">
          <b-form-tag
            :title="tag" :disabled="disabled"
            pill variant="dark"
            @remove="removeTag(tag)"
          >
            {{ tag }}
          </b-form-tag>
        </li>
      </ul>
    </template>
  </b-form-tags>
</template>

<script>
export default {
  name: 'MultipleTagsSelect',

  props: {
    value: { type: Array, required: true },
    options: { type: Array, required: true },
    buttonText: { type: String, required: true }
  },

  computed: {
    availableOptions () {
      return this.options.filter(opt => this.value.indexOf(opt.text) === -1)
    }
  }
}
</script>

<style lang="scss" scoped>
.b-form-tags {
  border: 0;
  padding: 0;

  @include media-breakpoint-up($layout-bp) {
    ul li {
      display: block;
    }
  }

  .btn-group {
    vertical-align: baseline;
  }

  .tags-dropdown {
    margin-right: .5rem;
    margin-bottom: .25rem;
  }

  .list-inline {
    margin: 0;

    &-item {
      margin-bottom: .25rem;
      display: inline-block;
    }
  }

  ::v-deep {
    .dropdown-menu {
      max-height: 30vh;
      overflow-y: auto;
      max-width: 100vw;
    }

    .b-form-tag {
      font-weight: $font-weight-bold;
      text-transform: capitalize;
      padding-top: 0;
      padding-bottom: 0;
      border: $line;
      margin-right: 0;
    }

    .b-form-tag-remove {
      opacity: 1;
      line-height: 0;
      height: 15px;
      text-shadow: none;
    }
  }
}
</style>
