<template>
  <div class="library">
    <!-- BACKGROUND (mode) -->
    <component :is="'library-' + mode" @open-node="openNode" />

    <!-- FOREGROUND (nodes) -->
    <node-book
      v-if="nodebook.length"
      :nodes="nodebook"
      class="library-node-book no-events-container"
      @open-node="openNode"
      @close-node="closeNode"
      @select-node="openNode"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import {
  LibraryList,
  LibraryMap,
  LibraryTree
} from './library'
import { NodeBook } from '@/components/layouts'


export default {
  name: 'Library',

  components: {
    LibraryList,
    LibraryMap,
    LibraryTree,
    NodeBook
  },

  props: {
    query: { type: Object, required: true }
  },

  computed: {
    ...mapGetters(['mode', 'nodebook'])
  },

  watch: {
    query (query) {
      this.$store.dispatch('UPDATE_NODEBOOK', query)
    }
  },

  methods: {
    openNode (ids) {
      this.$store.dispatch('OPEN_NODE', ids)
    },

    closeNode (ids) {
      this.$store.dispatch('CLOSE_NODE', ids)
    }
  },

  created () {
    this.$store.dispatch('UPDATE_NODEBOOK', this.query)
  }
}
</script>

<style lang="scss" scoped>
.library {
  height: 100%;

  &-node-book {
    position: relative;
    margin-bottom: -100%;
    // compensate header border
    height: calc(100% + 2px);
    top: calc(-100% - 2px);

    @include media-breakpoint-down($layout-bp-down) {
      margin-bottom: -100vh;
    }
  }
}
</style>
