<template>
  <div class="library-options">
    <b-form-group
      class="mode-select-group"
      :label="$t('options.display.title')"
      v-slot="{ ariaDescribedby }"
    >
      <b-form-radio-group
        id="mode-select"
        v-model="activeMode" :options="modes"
        name="mode-select" :aria-describedby="ariaDescribedby"
        buttons button-variant="outline-dark"
      />

      <b-button
        v-if="activeMode === 'map'"
        variant="outline-dark" class="d-block mt-2"
        @click="$store.dispatch('ROLL_RANDOM_NODES')"
      >
        {{ $t('options.display.shuffle') }}
      </b-button>

      <b-dropdown
        v-if="activeMode === 'list'"
        :text="$t('options.display.go-to-char')"
        variant="outline-dark" class="char-select d-block mt-2"
        dropright
      >
        <b-dropdown-item-button
          class="text-center"
          v-for="char in availableCharacters" :key="char"
          @click="scrollToChar(char)"
        >
          {{ char }}
        </b-dropdown-item-button>
      </b-dropdown>
    </b-form-group>

    <!-- LIST + MAP ONLY -->
    <b-form-group
      class="filters-group"
      v-if="mode !== 'tree'"
      :label="$t('options.filters.title')"
    >
      <div class="filters-mini-group mb-1">
        <b-form-checkbox
          v-model="tagsIsActive" name="tags-button"
          button class="toggle-btn"
          :class="tagsIsActive ? null : 'collapsed'"
          :aria-expanded="tagsIsActive ? 'true' : 'false'"
          aria-controls="tags-select"
          button-variant="outline-dark"
        >
          {{ $t('options.filters.choices.tags') }}
        </b-form-checkbox>

        <multiple-tags-select
          v-if="tagsIsActive"
          id="tags-select" :button-text="$t('options.filters.add')"
          v-model="activeTags" :options="tagsOptions"
        />
      </div>

      <div class="filters-mini-group align-items-center">
        <b-form-checkbox
          v-model="strangenessIsActive" name="tags-button"
          button class="toggle-btn"
          :class="strangenessIsActive ? null : 'collapsed'"
          :aria-expanded="strangenessIsActive ? 'true' : 'false'"
          aria-controls="strangeness-input"
          button-variant="outline-dark"
        >
          {{ $t('options.filters.choices.strangeness') }}
        </b-form-checkbox>

        <div v-if="strangenessIsActive" class="strangeness-input-wrapper">
          <b-form-input
            id="strangeness-input"
            type="range" min="1" max="5"
            v-model="activeStrangeness"
          />
        </div>
      </div>
    </b-form-group>

    <!-- LIST + MAP ONLY -->
    <search-input v-if="mode !== 'tree'" v-model="activeSearch" />

    <!-- TREE ONLY -->
    <b-form-group v-else id="depart-group" :label="$t('options.display.select-text')">
      <b-dropdown id="text-depart-select" variant="outline-dark" right>
        <template #button-content>
          <node-view-title v-if="activeNode" :node="activeNode" />
        </template>

        <b-dropdown-item v-for="node in nodesDepartsOptions" :key="node.id" @click="activeNodeId = node.id">
          <node-view-title :node="node" block />
        </b-dropdown-item>
      </b-dropdown>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { MultipleTagsSelect, SearchInput } from '@/components/formItems'
import { NodeViewTitle } from '@/components/nodes'


export default {
  name: 'LibraryOptions',

  components: {
    NodeViewTitle,
    MultipleTagsSelect,
    SearchInput
  },

  data () {
    return {
      modes: [
        { text: this.$t('options.display.choices.tree-map'), value: 'tree' },
        { text: this.$t('options.display.choices.card-map'), value: 'map' },
        { text: this.$t('options.display.choices.card-list'), value: 'list' }
      ],
      selectedTags: [],
      tagsIsActive: false,
      strangenessIsActive: false
    }
  },

  computed: {
    ...mapGetters([
      'nodesDepartsOptions',
      'nodesDeparts',
      'node',
      'orderedTextsDepart',
      'tagsOptions',
      'nodebook',
      'mode',
      'nodeDepartId',
      'search',
      'tags',
      'strangeness'
    ]),

    availableCharacters () {
      if (!this.orderedTextsDepart) return []
      return this.orderedTextsDepart.map(group => group[0])
    },

    activeNodeId: {
      get () { return this.nodeDepartId },
      set (value) {
        this.$store.dispatch('SET_NODE_DEPART_ID', value)
      }
    },

    activeNode () {
      return this.node(this.nodeDepartId)
    },

    activeMode: {
      get () { return this.mode },
      set (value) {
        this.$store.dispatch('UPDATE_QUERY_MODE', value)
      }
    },

    activeSearch: {
      get () { return this.search },
      set (value) {
        this.$store.commit('SET_SEARCH', value)
      }
    },

    activeTags: {
      get () { return this.tags },
      set (value) {
        this.$store.commit('UPDATE_TAGS', value)
      }
    },

    activeStrangeness: {
      get () { return this.strangeness },
      set (value) {
        this.$store.commit('UPDATE_STRANGENESS', parseInt(value))
      }
    }
  },

  watch: {
    strangenessIsActive (activate) {
      if (activate) {
        this.$store.commit('UPDATE_STRANGENESS', 1)
      } else {
        this.$store.commit('UPDATE_STRANGENESS', undefined)
      }
    },

    tagsIsActive () {
      this.$store.commit('UPDATE_TAGS', [])
    }
  },

  methods: {
    scrollToChar (char) {
      const h = document.querySelector(`h3[id=${char}]`)
      if (h) {
        h.scrollIntoView({
          behavior: navigator.userAgent.indexOf('Safari') !== -1 ? 'instant' : 'smooth'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.library-options {
  padding: 0 $header-spacer-sm $header-spacer-sm;
  background-color: $body-bg;
  width: 100%;


  @include media-breakpoint-down($layout-bp-down) {
    font-size: 0.9rem;

    ::v-deep {
      .btn, .form-control, .input-group-text {
        font-size: inherit;
      }
    }
  }

  @include media-breakpoint-up($size-bp) {
    padding: 0 $header-spacer $header-spacer;
    display: flex;
    justify-content: space-between;
  }


  ::v-deep {
    @include media-breakpoint-up($size-bp) {
      flex-wrap: wrap;

      fieldset {
        margin-bottom: 0;

        &:last-child {
          flex-shrink: 2;
        }
      }
    }

    > :last-child {
      margin-bottom: 0;
    }

    .mode-select-group {
      > div {
        line-height: 0;
      }

      label {
        border-color: transparent;

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active {
          background-color: transparent;
          color: $black;
        }
      }

      .char-select {
        line-height: 1;

        .dropdown-menu {
          max-height: 200px;
          overflow: auto;
          min-width: 3rem;
        }
      }
    }

    .filters-group {
      flex-grow: 2;

      @include media-breakpoint-up($size-bp) {
        padding: 0 2rem;
        max-width: 40%;
      }

      .filters-mini-group {
        display: flex;
        line-height: 0;
      }

      .toggle-btn label {
        border-color: transparent;
        margin-right: .5rem;

        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active {
          border-color: $black;
          background-color: transparent;
          color: $black;
        }
      }

      .strangeness-input-wrapper {
        min-width: 150px;
        &::before,
        &::after {
          content: '';
          display: inline-block;
          width: 3px;
          height: 20px;
          background-color: $black;
          position: absolute;
        }

        &::after {
          margin-left: -2px;
        }
      }
    }
  }

  #depart-group {
    width: 100%;

    @include media-breakpoint-up($size-bp) {
      max-width: 50%;
    }
  }

  #text-depart-select ::v-deep {
    width: 100%;

    .dropdown-menu {
      max-height: 300px;
      min-width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      margin-left: 0;
    }

    .dropdown-toggle {
      border-radius: 0 !important;
      width: 100%;
      padding-right: 1rem;
      padding-top: .15em;

      .node-view-title {
        width: 100%;

        text-align: left;
        display: inline-block;
        margin: 0;
        line-height: 1.25;

        &-container {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      &::after {
        margin-left: 0;
        vertical-align: .35em;
      }
    }

    .dropdown-item {
      white-space: unset;
    }
  }
}
</style>
